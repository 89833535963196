import ax from 'axios'
import type { AxiosResponse } from 'axios'
import type {
	AllTemplatesResponse,
	Applicant,
	ApplicantExportForCSV,
	ApplicantStatus,
	ApplicantWithRelationships,
	ApplicationDataApplicationSubmission,
	ApplicationDataToTechApplicationSubmission,
	ApplicationLead,
	ApplicationSubmission,
	AutomationIn,
	AutomationOut,
	Availability,
	Batch,
	City,
	CommonWhatsappSrcInterfacesWaTemplateCategory,
	Coordinator,
	CoordinatorResponse,
	Evaluation,
	EvaluationQuestion,
	EvaluationResponse,
	EvaluationSession,
	Exam,
	ExamAnswer,
	ExamChapter,
	ExamEvent,
	ExamEventBase,
	ExamQuestion,
	ExamQuestionUpdate,
	ExamSession,
	ExamSessionAnswer,
	ExamSessionRequest,
	ExamSessionWithAnswers,
	GetApplicantResponse,
	GetMsgTemplatesQueryParams,
	Interview,
	InterviewWithRelationships,
	ListMsgsResponse,
	Message,
	NewApplicant,
	NewPassword,
	PaymentRequestIn,
	PaymentRequestOut,
	Person,
	PersonUpdate,
	ProjectSettings,
	QuestionUpdate,
	Region,
	School,
	SchoolBase,
	SchoolWithCoordinator,
	StorageFile,
	StudentPaymentBase,
	StudentPaymentRelations,
	TemplatePayload,
	TemplateResData,
	TemplateResponse,
	UpdatePassword,
	UserCreate,
	UserPublic,
	UserRegister,
	UserUpdate,
	UserUpdateMe,
	UsersPublic,
	WaSuccessResponse,
	WhatsappMsg,
} from './types.d'

export const axios = ax.create({
	baseURL: import.meta.env.VITE_BAGEL_BASE_URL,
})
export async function checkDb(): Promise<AxiosResponse<Record<string, any>>> {
	return axios.get('/check-db')
}
export const dataExports = {
	exportApplicants: async (
		routeProtectionKey?: string,
	): Promise<AxiosResponse<ApplicantExportForCSV[]>> => axios.get(`/data_exports/export_applicants/${routeProtectionKey}`),
}
export const studentPayment = {
	get: async (
		applicantId?: string,
	): Promise<AxiosResponse<StudentPaymentRelations>> => axios.get(`/student_payment/${applicantId}`),
	put: async (
		studentPaymentBase: StudentPaymentBase,
		applicantId?: string,
	): Promise<AxiosResponse<StudentPaymentRelations>> => axios.put(`/student_payment/${applicantId}`, studentPaymentBase),
	delete: async (applicantId?: string) => axios.delete(`/student_payment/${applicantId}`),
	post: async (
		studentPaymentBase: StudentPaymentBase,
	): Promise<AxiosResponse<StudentPaymentRelations>> => axios.post('/student_payment/', studentPaymentBase),
	validateUnpaidPaymentRequest: async (
		applicantId?: string,
	): Promise<AxiosResponse<StudentPaymentRelations>> => axios.get('/student_payment/validate_unpaid_payment_request', {
		params: { applicant_id: applicantId },
	}),
}
export const emailTemplates = {
	getByFileName: async (fileName?: string) => axios.get(`/email_templates/${fileName}`),
	delete: async (
		fileName?: string,
	): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/email_templates/${fileName}`),
	get: async (): Promise<AxiosResponse<Record<string, any>[]>> => axios.get('/email_templates'),
	post: async (): Promise<AxiosResponse<StorageFile>> => axios.post('/email_templates', {}),
}
export const automation = {
	getByAutomationId: async (
		automationId?: string,
	): Promise<AxiosResponse<AutomationOut>> => axios.get(`/automation/${automationId}`),
	put: async (
		automationIn: AutomationIn,
		automationId?: string,
	): Promise<AxiosResponse<AutomationOut>> => axios.put(`/automation/${automationId}`, automationIn),
	delete: async (automationId?: string) => axios.delete(`/automation/${automationId}`),
	get: async (): Promise<AxiosResponse<AutomationOut[]>> => axios.get('/automation'),
	post: async (
		automationIn: AutomationIn,
	): Promise<AxiosResponse<AutomationOut>> => axios.post('/automation', automationIn),
}
export const exam = {
	getByExamId: async (
		examId?: string,
	): Promise<AxiosResponse<Record<string, any>>> => axios.get(`/exam/${examId}`),
	put: async (
		exam: Exam,
		examId?: string,
	): Promise<AxiosResponse<Exam | null>> => axios.put(`/exam/${examId}`, exam),
	delete: async (
		examId?: string,
	): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/exam/${examId}`),
	get: async (): Promise<AxiosResponse<Exam[]>> => axios.get('/exam'),
	post: async (exam: Exam): Promise<AxiosResponse<Exam>> => axios.post('/exam', exam),
	examEvents: {
		put: async (
			examEventBase: ExamEventBase,
			eventId?: string,
		): Promise<AxiosResponse<ExamEvent>> => axios.put(`/exam/exam_events/${eventId}`, examEventBase),
		delete: async (eventId?: string) => axios.delete(`/exam/exam_events/${eventId}`),
		get: async (latest: boolean = true): Promise<AxiosResponse<ExamEvent[]>> => axios.get('/exam/exam_events', { params: { latest } }),
		post: async (
			examEventBase: ExamEventBase,
		): Promise<AxiosResponse<ExamEvent>> => axios.post('/exam/exam_events', examEventBase),
	},
	session: {
		score: async (sessionId?: string): Promise<AxiosResponse<ExamSession>> => axios.post(`/exam/session/${sessionId}/score`, {}),
		delete: async (sessionId?: string): Promise<AxiosResponse<ExamSession>> => axios.post(`/exam/session/${sessionId}/delete`, {}),
		finish: async (sessionId?: string): Promise<AxiosResponse<ExamSession>> => axios.post(`/exam/session/${sessionId}/finish`, {}),
		answer: async (
			examSessionAnswer: ExamSessionAnswer,
			examSessionId?: string,
		): Promise<AxiosResponse<ExamSession>> => axios.post(`/exam/session/${examSessionId}/answer`, examSessionAnswer),
	},
	manual: async (
		examSessionRequest: ExamSessionRequest,
		examId?: string,
	): Promise<AxiosResponse<ExamSessionWithAnswers>> => axios.post(`/exam/${examId}/manual`, examSessionRequest),
	start: async (
		examSessionRequest: ExamSessionRequest,
		examId?: string,
	): Promise<AxiosResponse<ExamSessionWithAnswers>> => axios.post(`/exam/${examId}/start`, examSessionRequest),
	chapter: {
		getByChapterId: async (
			chapterId?: string,
		): Promise<AxiosResponse<ExamChapter>> => axios.get(`/exam/chapter/${chapterId}`),
		put: async (
			examChapter: ExamChapter,
			chapterId?: string,
		): Promise<AxiosResponse<ExamChapter | null>> => axios.put(`/exam/chapter/${chapterId}`, examChapter),
		delete: async (
			chapterId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/exam/chapter/${chapterId}`),
		get: async (examId?: string): Promise<AxiosResponse<ExamChapter[]>> => axios.get(`/exam/${examId}/chapter/`),
		post: async (
			examChapter: ExamChapter,
			examId?: string,
		): Promise<AxiosResponse<ExamChapter>> => axios.post(`/exam/${examId}/chapter/`, examChapter),
	},
	question: {
		post: async (
			examQuestionUpdate: ExamQuestionUpdate,
		): Promise<AxiosResponse<ExamQuestion>> => axios.post('/exam/question', examQuestionUpdate),
		getByQuestionId: async (
			questionId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.get(`/exam/question/${questionId}`),
		put: async (
			examQuestionUpdate: ExamQuestionUpdate,
			questionId?: string,
		): Promise<AxiosResponse<ExamQuestion>> => axios.put(`/exam/question/${questionId}`, examQuestionUpdate),
		delete: async (
			questionId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/exam/question/${questionId}`),
		get: async (chapterId?: string): Promise<AxiosResponse<ExamQuestion[]>> => axios.get(`/exam/${chapterId}/question/`),
	},
	answer: {
		getByAnswerId: async (
			answerId?: string,
		): Promise<AxiosResponse<ExamAnswer>> => axios.get(`/exam/answer/${answerId}`),
		put: async (
			examAnswer: ExamAnswer,
			answerId?: string,
		): Promise<AxiosResponse<ExamAnswer>> => axios.put(`/exam/answer/${answerId}`, examAnswer),
		delete: async (
			answerId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/exam/answer/${answerId}`),
		get: async (questionId?: string): Promise<AxiosResponse<ExamAnswer[]>> => axios.get(`/exam/${questionId}/answer`),
		post: async (
			examAnswer: ExamAnswer,
			questionId?: string,
		): Promise<AxiosResponse<ExamAnswer>> => axios.post(`/exam/${questionId}/answer`, examAnswer),
	},
}
export const submit = {
	runAutomation: async (): Promise<AxiosResponse<Record<string, any>>> => axios.post('/submit/run_automation', {}),
	application: async (
		applicationDataApplicationSubmission: ApplicationDataApplicationSubmission,
	): Promise<AxiosResponse<ApplicationSubmission>> => axios.post('/submit/application', applicationDataApplicationSubmission),
	applicationLead: async (
		applicationLead: ApplicationLead,
	): Promise<AxiosResponse<Record<string, any>>> => axios.post('/submit/application_lead', applicationLead),
	applicationToTech: async (
		applicationDataToTechApplicationSubmission: ApplicationDataToTechApplicationSubmission,
	): Promise<AxiosResponse<Record<string, any>>> => axios.post(
		'/submit/application-to-tech',
		applicationDataToTechApplicationSubmission,
	),
}
export const api = {
	city: {
		getByCityId: async (cityId?: string): Promise<AxiosResponse<City>> => axios.get(`/api/city/${cityId}`),
		put: async (city: City, cityId?: string): Promise<AxiosResponse<City>> => axios.put(`/api/city/${cityId}`, city),
		delete: async (
			cityId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/api/city/${cityId}`),
		get: async (): Promise<AxiosResponse<City[]>> => axios.get('/api/city'),
		post: async (city: City): Promise<AxiosResponse<City>> => axios.post('/api/city', city),
	},
	school: {
		getBySchoolId: async (
			schoolId?: string,
		): Promise<AxiosResponse<SchoolWithCoordinator>> => axios.get(`/api/school/${schoolId}`),
		put: async (
			updatedSchool: Record<string, any>,
			schoolId?: string,
		): Promise<AxiosResponse<School | null>> => axios.put(`/api/school/${schoolId}`, updatedSchool),
		delete: async (
			schoolId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/api/school/${schoolId}`),
		get: async (): Promise<AxiosResponse<SchoolWithCoordinator[]>> => axios.get('/api/school'),
		post: async (
			schoolBase: SchoolBase,
		): Promise<AxiosResponse<SchoolWithCoordinator>> => axios.post('/api/school', schoolBase),
	},
	region: {
		getAll: async (): Promise<AxiosResponse<Region[]>> => axios.get('/api/region'),
		post: async (region: Region): Promise<AxiosResponse<Region>> => axios.post('/api/region', region),
		get: async (regionId?: string): Promise<AxiosResponse<Region>> => axios.get(`/api/region/${regionId}`),
		put: async (
			region: Region,
			regionId?: string,
		): Promise<AxiosResponse<Region | null>> => axios.put(`/api/region/${regionId}`, region),
		delete: async (
			regionId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/api/region/${regionId}`),
	},
	batch: {
		getByBatchId: async (batchId?: string): Promise<AxiosResponse<Batch>> => axios.get(`/api/batch/${batchId}`),
		put: async (
			batch: Batch,
			batchId?: string,
		): Promise<AxiosResponse<Batch>> => axios.put(`/api/batch/${batchId}`, batch),
		get: async (): Promise<AxiosResponse<Batch[]>> => axios.get('/api/batch'),
		post: async (batch: Batch): Promise<AxiosResponse<Batch>> => axios.post('/api/batch', batch),
		applicants: {
			paymentLinks: async (
				applicantIds: string[],
			): Promise<AxiosResponse<Record<string, any>>> => axios.put('/api/batch/applicants/payment_links', applicantIds),
		},
	},
	applicants: async (
		batchId?: string,
		isArchived?: boolean,
	): Promise<AxiosResponse<GetApplicantResponse[]>> => axios.get(`/api/applicants/${batchId}`, {
		params: { is_archived: isArchived },
	}),
	applicant: {
		get: async (
			applicantId?: string,
		): Promise<AxiosResponse<ApplicantWithRelationships>> => axios.get(`/api/applicant/${applicantId}`),
		put: async (
			applicantUpdate: Record<string, any>,
			applicantId?: string,
		): Promise<AxiosResponse<Applicant>> => axios.put(`/api/applicant/${applicantId}`, applicantUpdate),
		post: async (
			newApplicant: NewApplicant,
			batchId?: string,
		): Promise<AxiosResponse<GetApplicantResponse>> => axios.post(`/api/applicant/${batchId}`, newApplicant),
		status: async (
			applicantId?: string,
			status?: ApplicantStatus,
		): Promise<AxiosResponse<Applicant>> => axios.put(
			`/api/applicant/${applicantId}/status`,
			{},
			{ params: { status } },
		),
	},
	bulk: {
		applicants: async (
			applicantIds: string[],
			status?: ApplicantStatus,
		): Promise<AxiosResponse<Record<string, any>>> => axios.put(`/api/bulk/applicants/${status}`, applicantIds),
	},
	chat: async (
		limit: number = 100,
		phoneNo?: string,
		skip?: number,
	): Promise<AxiosResponse<WhatsappMsg[]>> => axios.get(`/api/chat/${phoneNo}`, { params: { skip, limit } }),
	coordinator: {
		getById: async (id?: string): Promise<AxiosResponse<Coordinator>> => axios.get(`/api/coordinator/${id}`),
		get: async (): Promise<AxiosResponse<CoordinatorResponse[]>> => axios.get('/api/coordinator'),
		post: async (
			coordinatorDict: Record<string, any>,
		): Promise<AxiosResponse<Coordinator>> => axios.post('/api/coordinator', coordinatorDict),
		put: async (
			updateData: Record<string, any>,
			coordinatorId?: string,
		): Promise<AxiosResponse<Coordinator>> => axios.put(`/api/coordinator/${coordinatorId}`, updateData),
	},
	availability: {
		post: async (
			availabilityList: Record<string, any>[],
			coordinatorId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.post(`/api/availability/${coordinatorId}`, availabilityList),
		get: async (
			coordinatorId?: string,
		): Promise<AxiosResponse<Availability[]>> => axios.get(`/api/availability/${coordinatorId}`),
	},
	interviews: async (
		coordinatorId?: string,
	): Promise<AxiosResponse<InterviewWithRelationships[]>> => axios.get(`/api/interviews/${coordinatorId}`),
	interview: {
		post: async (
			details: Record<string, any>,
			applicantId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.post(`/api/interview/${applicantId}`, details),
		put: async (
			updateData: Record<string, any>,
			interviewId?: string,
		): Promise<AxiosResponse<Interview>> => axios.put(`/api/interview/${interviewId}`, updateData),
		cancel: async (
			details: Record<string, any>,
			interviewId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.post('/api/interview/cancel', details, {
			params: { interview_id: interviewId },
		}),
	},
	bookingAvailability: async (
		schoolId?: string,
	): Promise<AxiosResponse<Record<string, any>[]>> => axios.get(`/api/booking-availability/${schoolId}`),
	bookingAvailabilityToTech: async (): Promise<
		AxiosResponse<Record<string, any>[]>
	> => axios.get('/api/booking-availability-to-tech'),
}
export const projectSettings = {
	delete: async (key?: string): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/project_settings/${key}`),
	getById: async (id?: string): Promise<AxiosResponse<ProjectSettings>> => axios.get(`/project_settings/${id}`),
	put: async (projectSettings: ProjectSettings, id?: string) => axios.put(`/project_settings/${id}`, projectSettings),
	get: async (): Promise<AxiosResponse<ProjectSettings[]>> => axios.get('/project_settings'),
	post: async (projectSettings: ProjectSettings) => axios.post('/project_settings', projectSettings),
}
export const data = {
	sendParentsWhatsapp: async (
		template?: string,
	): Promise<AxiosResponse<Record<string, any>>> => axios.post(`/data/send_parents_whatsapp/${template}`, {}),
	migrateTranslations: async (): Promise<AxiosResponse<Record<string, any>>> => axios.post('/data/migrate_translations', {}),
	migrateApplicants: async () => axios.post('/data/migrate_applicants', {}),
	migrate: async (): Promise<AxiosResponse<Record<string, any>>> => axios.post('/data/migrate', {}),
}
export const person = {
	get: async (personId?: string): Promise<AxiosResponse<Person>> => axios.get(`/person/${personId}`),
	put: async (
		personUpdate: PersonUpdate,
		personId?: string,
	): Promise<AxiosResponse<Person>> => axios.put(`/person/${personId}`, personUpdate),
}
export const evaluation = {
	get: async (): Promise<AxiosResponse<EvaluationResponse[]>> => axios.get('/evaluation'),
	post: async (): Promise<AxiosResponse<Evaluation>> => axios.post('/evaluation', {}),
	addData: async (): Promise<AxiosResponse<Record<string, any>>> => axios.post('/evaluation/add_data', {}),
	question: {
		put: async (
			questionUpdate: QuestionUpdate,
			questionId?: string,
		): Promise<AxiosResponse<EvaluationQuestion>> => axios.put(`/evaluation/question/${questionId}`, questionUpdate),
		delete: async (
			questionId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/evaluation/question/${questionId}`),
		post: async (
			evaluationQuestion: EvaluationQuestion,
			evaluationId?: string,
		): Promise<AxiosResponse<EvaluationQuestion>> => axios.post(`/evaluation/question/${evaluationId}`, evaluationQuestion),
	},
	session: {
		put: async (
			sessionDict: Record<string, any>,
			sessionId?: string,
		): Promise<AxiosResponse<Record<string, any>>> => axios.put(`/evaluation/session/${sessionId}`, sessionDict),
		post: async (
			sessionDict: Record<string, any>,
			evaluationId?: string,
		): Promise<AxiosResponse<EvaluationSession>> => axios.post(`/evaluation/session/${evaluationId}`, sessionDict),
	},
}
export const run = {
	hourly: async (): Promise<AxiosResponse<Record<string, any>>> => axios.post('/run/hourly', {}),
	daily: async (): Promise<AxiosResponse<Record<string, any>>> => axios.post('/run/daily', {}),
}
export const files = {
	get: async (id?: string): Promise<AxiosResponse<StorageFile>> => axios.get(`/files/${id}`),
	put: async (
		storageFile: StorageFile,
		id?: string,
	): Promise<AxiosResponse<StorageFile>> => axios.put(`/files/${id}`, storageFile),
	delete: async (id?: string): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/files/${id}`),
	post: async (): Promise<AxiosResponse<StorageFile>> => axios.post('/files', {}),
	upload: async (): Promise<AxiosResponse<StorageFile>> => axios.post('/files/upload', {}),
}
export const staticFiles = {
	get: async (id?: string): Promise<AxiosResponse<StorageFile>> => axios.get(`/static_files/${id}`),
	put: async (
		storageFile: StorageFile,
		id?: string,
	): Promise<AxiosResponse<StorageFile>> => axios.put(`/static_files/${id}`, storageFile),
	delete: async (id?: string): Promise<AxiosResponse<Record<string, any>>> => axios.delete(`/static_files/${id}`),
	post: async (): Promise<AxiosResponse<StorageFile>> => axios.post('/static_files', {}),
	upload: async (): Promise<AxiosResponse<StorageFile>> => axios.post('/static_files/upload', {}),
}
export const webhook = {
	receiveWhatsapp: {
		get: async (hubVerifyToken?: string, hubChallenge?: string) => axios.get('/webhook/receive_whatsapp', {
			params: {
				'hub.verify_token': hubVerifyToken,
				'hub.challenge': hubChallenge,
			},
		}),
		post: async (payload: Record<string, any>) => axios.post('/webhook/receive_whatsapp', payload),
	},
}
export const whatsapp = {
	send: async (whatsappMsg: WhatsappMsg): Promise<AxiosResponse<WhatsappMsg>> => axios.post('/whatsapp/send', whatsappMsg),
	template: {
		get: async (templateId?: string): Promise<AxiosResponse<TemplateResData>> => axios.get(`/whatsapp/template/${templateId}`),
		post: async (
			getMsgTemplatesQueryParams: GetMsgTemplatesQueryParams,
		): Promise<AxiosResponse<AllTemplatesResponse>> => axios.post('/whatsapp/template', getMsgTemplatesQueryParams),
		delete: async (
			templateId?: string,
			templateName?: string,
		): Promise<AxiosResponse<WaSuccessResponse>> => axios.delete('/whatsapp/template', {
			params: { template_id: templateId, template_name: templateName },
		}),
		name: async (
			templateName?: string,
		): Promise<AxiosResponse<AllTemplatesResponse>> => axios.get(`/whatsapp/template/name/${templateName}`),
		new: async (
			templatePayload: TemplatePayload,
		): Promise<AxiosResponse<TemplateResponse>> => axios.post('/whatsapp/template/new', templatePayload),
		category: async (
			templateId?: string,
			category?: CommonWhatsappSrcInterfacesWaTemplateCategory,
		): Promise<AxiosResponse<WaSuccessResponse>> => axios.put(`/whatsapp/template/${templateId}/category/${category}`, {}),
		components: async (
			templatePayload: TemplatePayload,
			templateId?: string,
		): Promise<AxiosResponse<WaSuccessResponse>> => axios.put(`/whatsapp/template/${templateId}/components`, templatePayload),
	},
	message: async (messageId?: string): Promise<AxiosResponse<WhatsappMsg>> => axios.get(`/whatsapp/message/${messageId}`),
	messages: async (
		limit: number = 100,
		skip?: number,
		recipientTel?: string,
	): Promise<AxiosResponse<ListMsgsResponse>> => axios.get('/whatsapp/messages', {
		params: { skip, limit, recipient_tel: recipientTel },
	}),
}
export const auth = {
	login: {
		testToken: async (): Promise<AxiosResponse<UserPublic>> => axios.post('/auth/login/test-token', {}),
	},
	passwordRecovery: async (email?: string): Promise<AxiosResponse<Message>> => axios.post(`/auth/password-recovery/${email}`, {}),
	resetPassword: async (
		newPassword: NewPassword,
	): Promise<AxiosResponse<Record<string, any>>> => axios.post('/auth/reset-password', newPassword),
}
export const users = {
	getByUserId: async (userId?: string): Promise<AxiosResponse<UserPublic>> => axios.get(`/users/${userId}`),
	patch: async (
		userUpdate: UserUpdate,
		userId?: string,
	): Promise<AxiosResponse<UserPublic>> => axios.patch(`/users/${userId}`, userUpdate),
	delete: async (userId?: string): Promise<AxiosResponse<Message>> => axios.delete(`/users/${userId}`),
	get: async (
		limit: number = 100,
		skip?: number,
	): Promise<AxiosResponse<UsersPublic>> => axios.get('/users/', { params: { skip, limit } }),
	post: async (userCreate: UserCreate): Promise<AxiosResponse<UserPublic>> => axios.post('/users/', userCreate),
	me: {
		get: async (): Promise<AxiosResponse<UserPublic>> => axios.get('/users/me'),
		patch: async (
			userUpdateMe: UserUpdateMe,
		): Promise<AxiosResponse<UserPublic>> => axios.patch('/users/me', userUpdateMe),
		password: async (
			updatePassword: UpdatePassword,
		): Promise<AxiosResponse<Message>> => axios.patch('/users/me/password', updatePassword),
	},
	signup: async (
		userRegister: UserRegister,
	): Promise<AxiosResponse<UserPublic>> => axios.post('/users/signup', userRegister),
}
export const payment = {
	webhook: {
		meshulam: {
			get: async () => axios.get('/payment/webhook/meshulam'),
			post: async () => axios.post('/payment/webhook/meshulam', {}),
		},
	},
	availablePaymentProcessors: async (): Promise<AxiosResponse<string[]>> => axios.get('/payment/available_payment_processors'),
	paymentRequest: async (
		paymentRequestIn: PaymentRequestIn,
		overrideSuccessUrl?: string,
	): Promise<AxiosResponse<PaymentRequestOut>> => axios.post('/payment/payment_request', paymentRequestIn, {
		params: { override_success_url: overrideSuccessUrl },
	}),
}
