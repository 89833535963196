<script setup lang="ts">
import { Alert } from '@bagelink/vue'
import { RouterView } from 'vue-router'
import { useStore } from './store'

const store = useStore()

window.addEventListener('offline', (e) => {
	store.isOffline = true
})

window.addEventListener('online', (e) => {
	store.isOffline = false
	store.flushNetworkStack()
})
</script>

<template>
	<Alert
		v-if="store?.isOffline"
		message="יש בעיה באינטרנט! אפשר להמשיך כרגיל, נשלח את המידע ברגע שהרשת תחזור"
		class="offline"
		type="error"
	/>
	<ModalContainer />
	<RouterView />
</template>

<style src="./main.css"></style>

<style>
.offline {
	position: fixed !important;
	top: 0;
	right: calc(50% - 250px);
	z-index: 1000;
	padding: 15px 23px !important;
	max-width: 500px;
}
</style>
