export default {
	previousQuestion: 'שאלה קודמת',
	nextQuestion: 'שאלה הבאה',
	useEachAnswerOnce: 'שימו לב, ניתן להשתמש בכל תשובה רק פעם אחת',
	backToPrevChapter: 'חזרה לפרק הקודם',
	endExam: 'סיום המבחן',
	exam: {
		name: 'בחינת קבלה לתכנית ניצנים התשפ״ד',
		continue_session: 'אנחנו רואים שאתה באמצע מבחן, להמשך לחצו כאן',
		start: 'התחלה',
	},
}
