import { defineStore } from 'pinia'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import type {
	ExamSessionWithAnswers,
	ExamWithQuestions,
} from './types'
import { api } from './api'

function reportErrorToSentry(error: any) {
	const errorInfo = {
		url: error.config?.url,
		method: error.config?.method,
		data: error.config?.data,
		status: error.response?.status,
		response: error.response?.data,
	}

	Sentry.withScope((scope) => {
		scope.setExtras(errorInfo)
		Sentry.captureException(error)
	})
}

axios.defaults.baseURL = import.meta.env.VITE_BAGEL_BASE_URL as string
axios.defaults.timeout = 10000 // 10 seconds timeout

// Axios Interceptor for handling errors
axios.interceptors.response.use(
	response => response,
	async (error) => {
		reportErrorToSentry(error)
		return Promise.reject(error)
	}
)

export const useStore = defineStore('exam', {
	state: () => ({
		isOffline: false,
		currentQuestion: 0,
		user_answers: [] as Record<string, any>[],
		isSubmitting: false,
		isFinished: false,
		exam: null as null | ExamWithQuestions,
		exam_session: null as null | undefined | ExamSessionWithAnswers,
		applicant_id: '',
		applicant: {} as Record<string, any>,
		isLoading: false,
		networkStack: [] as any[],
		router: null as any,
	}),
	getters: {
		getCurrentQuestion: state => state.currentQuestion,
		getIsSubmitting: state => state.isSubmitting,
		getIsFinished: state => state.isFinished,
		getChapters: state => state.exam_session?.exam?.chapters.sort(
			(a, b) => Number(a.order) - Number(b.order)
		) || [],
	},
	actions: {
		async updatePerson(data: Record<string, any>) {
			const { person_id } = this.applicant
			if (!person_id) return
			await axios.put(`/person/${person_id}`, data)
		},
		async finishExam() {
			try {
				const session_id = this.exam_session?.id
				await axios.post(`/exam/session/${session_id}/finish`)

				await this.router.push('/exam-finish')
			} catch (e) {
				console.error(e)
			}
		},
		async loadApplicant(applicant_id: string) {
			const applicant = (await api.applicant.get(applicant_id)).data
			this.applicant = applicant
			this.applicant_id = applicant_id

			this.exam_session = applicant.exam_sessions.find(
				session => session.is_finished === false
			)

			const finished_session = applicant.exam_sessions.find(
				e => e.is_finished
			)

			if (this.exam_session) {
				this.user_answers = this.exam_session.user_answers || []

				;(this.exam_session.exam as ExamWithQuestions).chapters
					= this.exam_session.exam?.chapters.sort(
						(a, b) => Number(a.order) - Number(b.order)
					) || []
			} else if (finished_session && !applicant.allow_another_exam) {
				this.isFinished = true
				this.router.push('/exam-finish')
			}
		},
		async initExam(exam_id: string) {
			try {
				const { data: exam } = await axios.get(`/exam/${exam_id}`)
				this.exam = exam
			} catch (e) {
				console.error(e)
				setTimeout(() => {
					window.location.reload()
				}, 5000)
			}
		},
		continueSession() {
			if (!this.exam_session || !this.exam) return
			const firstChapter = this.getChapters[0]

			this.router.push(`/exam/${this.applicant_id}/chapter/${firstChapter.id}`)
		},

		async startExam() {
			if (this.isLoading) return
			this.isLoading = true
			if (!this.exam_session) {
				const payload = { applicant_id: this.applicant_id }
				const { data: exam_session } = await axios.post(
					`/exam/${this.exam?.id}/start`,
					payload
				)
				this.exam_session = exam_session
			}

			await this.router.push(
				`/exam/${this.applicant_id}/chapter/${this.getChapters[0].id}`
			)
			this.isLoading = false
		},
		nextQuestion() {
			this.currentQuestion++
		},
		previousQuestion() {
			this.currentQuestion--
		},
		async submitAnswer({
			answer_id,
			question_id,
			value,
		}: {
			answer_id?: string
			question_id: string
			value?: any
		}) {
			if (Array.isArray(value)) value = value.join(',')
			const answer = { answer_id, question_id, value }
			const index = this.user_answers.findIndex(
				a => a.question_id === question_id
			)
			if (index !== -1) this.user_answers[index] = answer
			else this.user_answers.push(answer)

			this.addToNetworkStack(answer)
		},
		setToLocalStore() {
			localStorage.setItem('networkStack', JSON.stringify(this.networkStack))
		},
		addToNetworkStack(answer: any) {
			this.networkStack.push(answer)
			this.setToLocalStore()
			void this.flushNetworkStack()
		},
		async flushNetworkStack() {
			const unsyncdAnswers = this.networkStack.filter(
				(answer: any) => !answer.is_synced
			)
			for (const answer of unsyncdAnswers) {
				try {
					await axios.post(
						`/exam/session/${this.exam_session?.id}/answer`,
						answer
					)
					answer.is_synced = true
				} catch (e) {
					console.error(e)
				}
			}
			this.setToLocalStore()
			const remaining = this.networkStack.filter(
				(answer: any) => !answer.is_synced
			)
			if (!remaining.length) setTimeout(async () => this.flushNetworkStack(), 5000)
		},
		resetExam() {
			this.currentQuestion = 0
			this.isSubmitting = false
			this.isFinished = false
		},
	},
})
